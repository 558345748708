import * as React from 'react';

import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { SearchDocumentType } from '@wix/client-search-sdk';

import { getWixImageUrl } from '../getWixImageUrl';
import { Thumbnail } from '../Thumbnail';
import { Title } from '../Title';
import { Price } from '../Price';

import { st, classes } from './GridLayoutItem.st.css';
import { ILayoutItemProps } from '../Layout.types';
import { AddToCartButton } from '../AddToCartButton';
import settingsParams from '../../../settingsParams';
import { Spec } from '../../../../../lib/specs';

const THUMBNAIL_WIDTH = {
  DESKTOP: 211,
  MOBILE: 130,
};

const getThumbnailWidth = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_WIDTH.MOBILE : THUMBNAIL_WIDTH.DESKTOP;

const THUMBNAIL_HEIGHT = {
  DESKTOP: 174,
  MOBILE: 105,
};

const getThumbnailHeight = (isMobile: boolean) =>
  isMobile ? THUMBNAIL_HEIGHT.MOBILE : THUMBNAIL_HEIGHT.DESKTOP;

interface IGridLayoutItemProps extends ILayoutItemProps {
  onAddToCartClick: React.MouseEventHandler;
}

export const GridLayoutItem: React.FC<IGridLayoutItemProps> = ({
  item,
  onClick,
  onLinkClick,
  onAddToCartClick,
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const settings = useSettings();
  const { experiments } = useExperiments();

  const isAddToCartExperimentEnabled = experiments.enabled(Spec.AddToCart);

  return (
    <li
      data-hook="grid-layout-item"
      className={st(classes.root, {
        mobileView: isMobile,
        fluid: isEditorX,
        addToCartEnabled: isAddToCartExperimentEnabled,
      })}
      onClick={onClick}
    >
      <Thumbnail
        title={item.title}
        onClick={onLinkClick}
        url={item.url}
        imageUrl={getWixImageUrl(
          item.image,
          getThumbnailWidth(isMobile),
          getThumbnailHeight(isMobile),
        )}
      />
      <div>
        <Title title={item.title} onClick={onLinkClick} url={item.url} />
      </div>
      {'price' in item && item.price && <Price price={item.price} />}
      {isAddToCartExperimentEnabled &&
        item.documentType === SearchDocumentType.Products &&
        settings.get(settingsParams.isProductsAddToCartEnabled) && (
          <AddToCartButton onClick={onAddToCartClick} inStock={item.inStock!} />
        )}
    </li>
  );
};
