import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { ISearchProductDocument } from '@wix/client-search-sdk';

import { IGridLayoutProps } from '../Layout.types';
import { GridLayoutItem } from './GridLayoutItem';
import { Spec } from '../../../../../lib/specs';
import { st, classes } from './GridLayout.st.css';

export const GridLayout: React.FC<IGridLayoutProps> = ({
  listRef,
  items,
  label,
  onItemClick,
  onItemLinkClick,
  onAddToCart,
  ...formatProps
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  const { experiments } = useExperiments();

  return (
    <div className={st(classes.container)}>
      <ul
        className={st(classes.root, {
          mobileView: isMobile,
          fluid: isEditorX,
          addToCartEnabled: experiments.enabled(Spec.AddToCart),
        })}
        data-hook="grid-layout"
        ref={listRef}
        tabIndex={-1}
        role="region list"
        aria-label={label}
      >
        {items.map((item, index) => {
          const handleClick: React.MouseEventHandler<HTMLElement> = e =>
            onItemClick?.(e, item, index);

          const handleLinkClick: React.MouseEventHandler<HTMLElement> = e =>
            onItemLinkClick?.(e, item, index);

          const onAddToCartClick: React.MouseEventHandler = () =>
            onAddToCart?.(item as ISearchProductDocument);

          return (
            <GridLayoutItem
              {...formatProps}
              key={index}
              item={item}
              onClick={handleClick}
              onLinkClick={handleLinkClick}
              onAddToCartClick={onAddToCartClick}
            />
          );
        })}
      </ul>
    </div>
  );
};
